.form-inputs {
    height: 38px;
}

.form-errors {
    font-size: 12px;
    color: red;
}

.error {
    border-color: red;
}
