.on-top {
    z-index: 1050;
}

.accordion {
    user-select: none;
}

.header {
    margin-bottom: 10px;
}

.data-point-modal {
    width: 90vw !important;
    max-width: 90vw !important;
}

.form-buttons {
    margin-left: auto;
    margin-right: 10px;
    border-radius: 10px;
    min-width: 80px;
}

.close-button {
    margin-right: 5px;
}

.modal-content {
    width: 50%;
}
