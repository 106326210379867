.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.config-container {
    padding-right: 0;
    padding-left: 0;
    padding-top: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}


.side-panel {
    height: 100%;
    border-radius: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.selection-component {
    padding-top: 1rem;
    justify-content: center;
    display: flex;
}

.disclaimer-button {
    background-color: #d8dee3;
    border: 1px #3b5872;
    color: black;
    font-weight: bold;
}
